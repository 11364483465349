<template>
	<div class="grid">
		<div class="col-10">
			<breadcrumb  :home="home" :model="britems" />
		</div>
		<div class="col-2">
			<Button type="button" label="Add User" icon="pi pi-user" class="p-button-rounded  mr-2" @click="add_user()"/>
		</div>
	</div>
	
	<div v-if="loading" id="loading">
        <div class="loader col-8"></div>
    </div>
	<div class="grid">
		<div class="col-7">
			<div class="card">
				<Toast/>

				<DataTable ref="dt" :loading="loading2" :value="products" v-model:selection="selectedProducts" dataKey="id"  responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Events Responses</h5>					
						</div>
					</template>
					
					<!-- <Column field="name" header="Name"  headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.emailAddress.name}}
						</template>
					</Column> -->
					<Column field="address" header="Email Address"  headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Email Address</span>
							{{slotProps.data.emailAddress.address}}
						</template>
					</Column>
					<Column field="type" header="Type"  headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title p-column-success">Type</span>
							{{slotProps.data.type}}
						</template>
					</Column>
					
					
					<Column field="response" header="Response"  headerStyle="width:34%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Response</span>
							<div :class="stockClass(slotProps.data)">
								{{slotProps.data.status.response}}
							</div>
						</template>
					</Column>
				
				</DataTable>

			</div>
		</div>
		<div class="col-5">
			<div class="card">
				<Toast/>

				<DataTable ref="dt" :loading="loading2" :value="manualusers" v-model:selection="selectedProducts" dataKey="id"  responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Events User</h5>					
						</div>
					</template>
					
					
					<Column field="address" header="Email Address"  headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Email Address</span>
							{{slotProps.data.email}}
						</template>
					</Column>

					<Column field="admin_approved" header="External"  headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">External</span>
							{{slotProps.data.admin_approved?'Yes':'No'}}
						</template>
					</Column>
					
				
				</DataTable>

			</div>
		</div>
	</div>
	<Dialog v-model:visible="adduser" :style="{width: '450px'}" header="Add required user" :modal="true">
		<div class="flex align-items-center justify-content-center">
			<div class="grid">
				<div class="col-4 mt-2">
					<h6 style="text-align: left;" class="required">User Email</h6>                                             
				</div>
				<div class="col-6">
					<InputText id="name" v-model.trim="user.email" required="true" autofocus :class="{'p-invalid':  !user.email}" />
					<small class="p-invalid" v-if="!user.email">Email is required.</small>
				</div>
			</div>
		</div>
		<template #footer>
			<Button label="Create" :disabled="!user.email" icon="pi pi-check" class="p-button-rounded p-button-warning mr-2" @click="submitrequireduser" />
			<Button label="Cancel" icon="pi pi-times" class="p-button-rounded p-button-warning mr-2" @click="adduser = false"/>
			
		</template>
	</Dialog>

</template>

<script>
import SNService from "../../service/SNService";
import  'primevue/breadcrumb';


export default {
	data() {
		return {
			home: {icon: 'pi pi-home', to: '/'},
            britems: [
                {label: 'Manage Events', to: '/sn'},
				{label: 'Invitees Response',disabled:true},
            ],
			products: null,
			user: {email:null},
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			product: {},
			manualusers: [],
			selectedProducts: null,
			filters: {},
			submitted: false,
			loading2 : true,
			loading: false,
			adduser:false
			
		}
	},
	productService: null,
	created() {
		this.srateService = new SNService();
	},
	mounted() {
		console.log(this.$route.params);
		this.initial_load();
	},
	methods: {
		initial_load(){
			this.loading2 = true;
			this.srateService.viewroster(this.$route.params.sysId,this.$route.params.eventId).then((data) => {
				console.log(data);
                this.products = data.data['ms'].value;
				this.manualusers = data.data['d']
                this.loading2 = false;
                this.new_disabled=false;
            }).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 6000});
                                }).finally(()=> {
                                    this.loading2 = false;
                                    this.new_disabled = false;
                                    this.product = {};
                                    this.productIdEdit=-1;
                                    this.productToBeDeleted = -1;
                                });			
		},
		managevent(){
			this.$router.push('/sn');
		},
		add_user(){
			this.adduser = true;
		},	
		submitrequireduser(){
			let email = this.user.email;
			this.loading = true;
			this.srateService.add_required_user(email,this.$route.params.sysId).then((res) => {
                                    console.log(res);
									this.adduser = false;
									this.initial_load();
                                }).catch(error => {
                                    console.log(error);
									alert("Server Error! while adding new user!");
                                }).finally(()=> {
                                    this.loading = false;
                                }); 	
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		stockClass(data) {
            return [
                {
                    'lowstock': data.status.response=='none',
                    'instock': data.status.response=='accepted'
                 }
            ];
        }
	
	}
}
</script>


<style scoped lang="scss">

#loading {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  opacity: 0.7;
  background-color: #fff;
  z-index: 1200;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.outofstock {
    font-weight: 700;
    color: #FF5252;
    text-decoration: line-through;
}

.lowstock {
    font-weight: 700;
    color: #FFA726;
}

.instock {
    font-weight: 700;
    color: #66BB6A;
}

::v-deep(.row-accessories) {
    background-color: rgba(0,0,0,.15) !important;
}
</style>            